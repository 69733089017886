import React, { Component } from 'react'
import AUX from '../../hoc/Aux_'
import { Link } from 'react-router-dom'
import { url } from '../../containers/MainContent/Pages/SignUp'

class Footer extends Component {
  constructor() {
    super()
    this.state = {
      email: '',
      name: '',
      subject: '',
      message: '',
      email_err: '',
      name_err: '',
      subject_err: '',
      message_err: '',
      return_msg: '',
      flag: false
    }

    this.handleChangeEmail = this.handleChangeEmail.bind(this)
    this.handleChangeName = this.handleChangeName.bind(this)
    this.handleChangeSubject = this.handleChangeSubject.bind(this)
    this.handleChangeMessage = this.handleChangeMessage.bind(this)
    this.handleSubmit1 = this.handleSubmit1.bind(this)
  }

  handleChangeEmail(e) {
    this.setState({ email: e.target.value })
    var EmailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (e.target.value === '') this.setState({ email_err: 'Required Field' })
    else if (EmailReg.test(e.target.value)) this.setState({ email_err: '' })
    else this.setState({ email_err: 'Enter Valid Email' })
  }

  handleChangeName(e) {
    this.setState({ name: e.target.value })
    if (e.target.value === '') this.setState({ name_err: 'Required Field' })
    else this.setState({ name_err: '' })
  }
  handleChangeSubject(e) {
    this.setState({ subject: e.target.value })
    if (e.target.value === '') this.setState({ subject_err: 'Required Field' })
    else this.setState({ subject_err: '' })
  }
  handleChangeMessage(e) {
    this.setState({ message: e.target.value })
    if (e.target.message === '') this.setState({ message_err: 'Required Field' })
    else this.setState({ message_err: '' })
  }

  handleSubmit1(e) {
    if (this.state.name === '') this.setState({ name_err: 'Required Field' })
    if (this.state.email === '') this.setState({ email_err: 'Required Field' })
    if (this.state.subject === '') this.setState({ subject_err: 'Required Field' })
    if (this.state.message === '') this.setState({ message_err: 'Required Field' })

    if (this.state.name === '' || this.state.email === '' || this.state.subject === '' || this.state.message === '') {
      this.setState({ return_msg: 'Fill All First', flag: true })
    } else {
      this.setState({ return_msg: 'Success.', flag: true })
      e.preventDefault()
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name: this.state.name,
          email: this.state.email,
          topic: this.state.subject,
          message: this.state.message
        })
      })
    }
  }

  render() {
    return (
      <AUX>
        <section className="pt-5 bg-dark" id="contact">
          <div className="container">
            <div className="row justify-content-center pt-5">
              <div className="col-md-8">
                <div className="text-center">
                  <div className="title-icon">
                    <i className="mdi mdi-lock-open-outline" />
                  </div>
                  <h3 className="section-title text-white pt-5">Contact us</h3>
                  <p className="section-subtitle pt-3 text-whte">
                    Working Hours
                    <br />
                    Monday - Friday: 9 a.m. - 6 p.m.
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="row mt-5">
                  <div className="col-lg-4">
                    <div className="single-contact text-center text-white">
                      <i className="mdi mdi-cellphone" />
                      <h4>Phone</h4>
                      <p>(650) 583-9474</p>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="single-contact text-center text-white">
                      <i className="mdi mdi-map-marker" />
                      <h4>Address</h4>
                      <p>1310 El Camino Real Suite C</p>
                      <p>San Bruno, CA 94066</p>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="single-contact text-center text-white">
                      <i className="mdi mdi-email-outline" />
                      <h4>Email</h4>
                      <p>allstarinsurance@hotmail.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center mt-5">
              <div className="col-lg-8 bg-white p-5 mt-4 rounded">
                <div className="custom-form">
                  <div id="message">
                    {this.state.flag ? (
                      <div className="alert alert-success">
                        <strong>{this.state.return_msg}</strong>
                      </div>
                    ) : null}
                  </div>
                  <form method="post" name="contact-form" id="contact-form" onSubmit={this.handleSubmit1}>
                    <div className="row">
                      <div className="col-lg-6 mt-3">
                        <input
                          type="text"
                          id="name"
                          value={this.state.name}
                          onChange={this.handleChangeName}
                          className="form-control"
                          placeholder="Name"
                        />
                        <span id="err">{this.state.name_err}</span>
                      </div>
                      <div className="col-lg-6 mt-3">
                        <input
                          type="text"
                          id="email"
                          className="form-control"
                          value={this.state.email}
                          onChange={this.handleChangeEmail}
                          placeholder="Enter email"
                        />
                        <span id="err">{this.state.email_err}</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 mt-3">
                        <input
                          type="text"
                          id="subject"
                          value={this.state.subject}
                          onChange={this.handleChangeSubject}
                          className="form-control"
                          placeholder="Your Subject.."
                        />
                        <span id="err">{this.state.subject_err}</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 mt-3">
                        <textarea
                          name="comments"
                          id="comments"
                          value={this.state.message}
                          onChange={this.handleChangeMessage}
                          rows="6"
                          className="form-control"
                          placeholder="Your message..."
                        />
                        <span id="err">{this.state.message_err}</span>
                      </div>
                    </div>
                    <div className="mt-4 text-center">
                      <input
                        type="button"
                        id="submit"
                        name="send"
                        onClick={this.handleSubmit1}
                        className="submitBnt btn btn-custom"
                        value="Submit"
                      />
                      <div id="simple-msg" />
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-md-8">
                        <p className="text-center submit-terms mb-0 mt-3">
                          This will email allstarinsurance@hotmail.com. We'll respond as soon as time permits.
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="row mt-5 pt-5 pb-2">
              <div className="col-md-12">
                <div className="text-white footer-alt">
                  <div className="float-left">
                    <p className="copyright-desc pb-0">2020 © All Star Insurance. All Rights Reserved</p>
                  </div>
                  <div className="float-right">
                    <ul className="list-inline social pb-0">
                      <li className="list-inline-item pl-2">
                        <p className="text-white">
                          <i className="mdi mdi-car" />
                        </p>
                      </li>
                      <li className="list-inline-item pl-2">
                        <p className="text-white">
                          <i className="mdi mdi-motorbike" />
                        </p>
                      </li>
                      <li className="list-inline-item pl-2">
                        <p className="text-white">
                          <i className="mdi mdi-hospital" />
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </AUX>
    )
  }
}

export default Footer
