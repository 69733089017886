import React, { Component } from "react"
import AUX from "../../../hoc/Aux_"
import { Link } from "react-router-dom"

class Features extends Component {
  render() {
    return (
      <AUX>
        <section className='section' id='features'>
          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col-md-8 text-center'>
                <div className='title-icon'>
                  <i className='mdi mdi-note-multiple-outline'></i>
                </div>
                <h3 className='section-title pt-5'>Our Team</h3>
                <p className='section-subtitle text-dark pt-3'>
                  Based in San Bruno and serving the entire state of California,
                  All-Star Insurance is your one-stop shop for all your
                  insurance coverage needs. We shop from over 100 companies to
                  ensure you receive the right amount of coverage for the lowest
                  rates available. With low down payments and low monthly
                  payments, you're bound to save money with us.
                </p>
              </div>
            </div>
            <div className='row vertical-content'>
              <div className='col-md-6 pt-4'>
                <img
                  src='images/service-1.png'
                  alt=''
                  className='img-fluid rounded'
                />
              </div>
              <div className='col-md-6'>
                <div className='features-box'>
                  <div className='features-icon'>
                    <i className='mdi mdi-eye text-custom'></i>
                  </div>
                  <div className='features-desc'>
                    <h5>We Cover Everyone</h5>
                    <p className='pt-2'>
                      Foreign, International, Matricular or No License?
                      <br />
                      We can ensure you.
                      <br />
                      <br />
                      DUI, Same Day SR22?
                      <br />
                      No problem, we can ensure you.
                      <br />
                      <br />
                      Teens, young, or newly licensed drivers? Tickets and
                      accidents?
                      <br />
                      Of course, we can ensure you too, and with lower rates
                      than you think.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='row vertical-content'>
              <div className='col-md-6'>
                <div className='features-box'>
                  <div className='features-icon'>
                    <i className='mdi mdi-chart-bubble text-custom'></i>
                  </div>
                  <div className='features-desc'>
                    <h5>Coverages</h5>
                    <p className='pt-2'>
                      Just like we can cover everyone, we cover just about
                      everything too. Auto Insurance, Motorcycle Insurance,
                      Boat/RV Insurance, Home/Renters Insurance, Same Day SR22,
                      Life/Health Insurance, Registration Service,
                      Commercial/Business Insurance, Renewals and Transfers,
                      Coverage for Teen Drives, Rideshare Insurance, and Mexico
                      Travel Insurance.
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-md-6 pt-4'>
                <img
                  src='images/service-2.png'
                  alt=''
                  className='img-fluid rounded'
                />
              </div>
            </div>
            <div className='row vertical-content'>
              <div className='col-md-6 pt-4'>
                <img
                  src='images/service-3.png'
                  alt=''
                  className='img-fluid rounded'
                />
              </div>
              <div className='col-md-6'>
                <div className='features-box'>
                  <div className='features-icon'>
                    <i className='mdi mdi-fingerprint text-custom'></i>
                  </div>
                  <div className='features-desc'>
                    <h5>Our Strategy</h5>
                    <p className='pt-2'>
                      Serving both English and Spanish Speakers, we run through
                      every available option with advanced data analysis
                      software, and then we hand pick from those results to
                      ensure that you get the best available options, at the
                      best available prices.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </AUX>
    )
  }
}

export default Features
