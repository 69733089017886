import React, { Component } from "react"
import { Link } from "react-router-dom"
import AUX from "../../../hoc/Aux_"
import * as actionTypes from "../../../store/action"
import { connect } from "react-redux"
import axios from "axios"

export const url = "https://nthzimj4yd.execute-api.us-west-1.amazonaws.com/dev"

class SignUp extends Component {
  constructor(props) {
    super(props)

    this.state = {
      name: "",
      email: "",
      address: "",
    }
    this.changeField = this.changeField.bind(this)
    this.submitEmail = this.submitEmail.bind(this)
  }

  changeField = (key, value) => {
    this.setState({
      [key]: value,
    })
  }

  submitEmail = async (e) => {
    e.preventDefault()
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...this.state, topic: "Request for Quote" }),
    })
    window.location.href = "/"
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <AUX>
        <div className='bg-custom1'>
          <section className=''>
            <div className='container-fluid'>
              <div className='col-lg-12'>
                <div className='home-icon'>
                  <Link to='index_1' className='text-white'>
                    <i id='my_icon' className='mdi mdi-home'></i>
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <section className='bg-custom section'>
            <div className='login-table'>
              <div className='login-table-cell'>
                <div className='container'>
                  <div className='row justify-content-center'>
                    <div className='col-lg-8'>
                      <div className='text-center'>
                        <h1 className='text-white'>All Star Insurance</h1>
                        <p className='text-white-50 text-center'>
                          Get a quote from All Star Insurance
                        </p>
                      </div>
                      <div className='bg-white p-4 mt-4 rounded'>
                        <form
                          className='login-form'
                          // action='index_1'
                          onSubmit={this.submitEmail}
                        >
                          <div className='row'>
                            <div className='col-lg-12 mt-4'>
                              <input
                                type='text'
                                value={this.state.name}
                                className='form-control'
                                placeholder='Name'
                                required=''
                                onChange={(e) =>
                                  this.changeField("name", e.target.value)
                                }
                              />
                            </div>
                            <div className='col-lg-12 mt-4'>
                              <input
                                type='email'
                                value={this.state.email}
                                className='form-control'
                                placeholder='Email'
                                required=''
                                onChange={(e) =>
                                  this.changeField("email", e.target.value)
                                }
                              />
                            </div>
                            <div className='col-lg-12 mt-4'>
                              <input
                                type='text'
                                value={this.state.address}
                                className='form-control'
                                placeholder='Address'
                                required=''
                                onChange={(e) =>
                                  this.changeField("address", e.target.value)
                                }
                              />
                            </div>

                            <div className='col-lg-12 mt-4 mb-3'>
                              <button className='btn btn-custom w-100'>
                                Get a Quote!
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </AUX>
    )
  }
}

// const mapStatetoProps = (state) => {
//   return {
//     loginpage: state.ui_red.loginpage,
//   }
// }

// const mapDispatchtoProps = (dispatch) => {
//   return {
//     UpdateLogin: () => dispatch({ type: actionTypes.LOGINPAGE, value: true }),
//     UpdateLoginAgain: () =>
//       dispatch({ type: actionTypes.LOGINPAGE, value: false }),
//   }
// }

export default SignUp
