import React, { Component } from "react"
import AUX from "../../../hoc/Aux_"

class Faq extends Component {
  render() {
    return (
      <AUX>
        <section className='section bg-light' id='faq'>
          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col-md-8'>
                <div className='text-center'>
                  <div className='title-icon'>
                    <i className='mdi mdi-comment-question-outline'></i>
                  </div>
                  <h3 className='section-title pt-5'>
                    Frequently Asked Questions
                  </h3>
                  <p className='section-subtitle pt-3 text-muted'>
                    Some general topics about what we do, and what insurance
                    covers.
                  </p>
                </div>
              </div>
            </div>
            <div className='row justify-content-center mt-5'>
              <div className='col-lg-6 faq-side'>
                <div className='faq pt-5'>
                  <p className='text-uppercase user-title mb-1'>WHAT?</p>
                  <div className='faq-ans'>
                    <h3 className='faq-title'>
                      <i className='mdi mdi-help-circle'></i> What is auto
                      insurance?
                    </h3>
                    <p className='faq-sub-title text-muted pt-2'>
                      Insurance, by definition, is a contract between you and
                      the insurance company. The contract stipulates that as
                      long as you pay the premium, the insurance company agrees
                      to pay for your covered losses if you experience an
                      accident, theft or vandalism, or your car is damaged by
                      certain causes. The amount you receive in compensation is
                      based on several factors, including your deductible and
                      the limit you choose for your policy.
                    </p>
                  </div>
                </div>
                <div className='faq pt-5'>
                  <p className='text-uppercase user-title mb-1 mb-1'>WHY?</p>
                  <div className='faq-ans'>
                    <h3 className='faq-title'>
                      <i className='mdi mdi-help-circle'></i> Why Is Auto
                      Insurance Important?
                    </h3>
                    <p className='faq-sub-title text-muted pt-2'>
                      When you get behind the wheel, you take a risk. You may
                      attempt to be the best possible driver, but you also have
                      to trust that everyone else on the road is driving well
                      and paying attention, too.
                    </p>

                    <p className='faq-sub-title text-muted pt-2'>
                      Auto insurance provides a safety net when drivers make
                      mistakes. In the event of an accident, you are at risk
                      financially. If the accident is not your fault, and the
                      other driver does not have adequate insurance, you have to
                      pay for all damages to your own car plus pay for any
                      medical bills if you are badly injured. When you are at
                      fault, you are typically liable for damages to the other
                      person’s vehicle as well as the medical costs of injured
                      victims.
                    </p>
                  </div>
                </div>
                <div className='faq pt-5'>
                  <p className='text-uppercase user-title mb-1'>HOW?</p>
                  <div className='faq-ans'>
                    <h3 className='faq-title'>
                      <i className='mdi mdi-help-circle'></i> How do I get
                      insurance?
                    </h3>
                    <p className='faq-sub-title text-muted pt-2'>
                      There are a number of ways to get car insurance. It is
                      important to understand that there are well-established
                      insurance companies that offer excellent car insurance
                      coverage, and there are companies that were built solely
                      to sell car insurance and make money.
                    </p>
                    <p className='faq-sub-title text-muted pt-2'>
                      Some auto insurance companies gain customers through
                      advertising and others through word-of-mouth and excellent
                      service. When you buy insurance through an independent
                      agent, you can eliminate confusion and find the best
                      policy for your budget and insurance needs.
                    </p>
                    <p className='faq-sub-title text-muted pt-2'>
                      Independent insurance agents do not sell policies for one
                      company; they shop from many different companies to find
                      you the best rates and coverage for your situation.
                    </p>
                  </div>
                </div>
              </div>

              <div className='col-lg-6 faq-side '>
                <div className='faq pt-5'>
                  <div className='faq-ans'>
                    <h3 className='faq-title'>
                      <i className='mdi mdi-help-circle'></i>Is it required?
                    </h3>
                    <p className='faq-sub-title text-muted pt-2'>
                      In California: yes.
                    </p>
                  </div>
                </div>
                <div className='faq pt-5'>
                  <p className='text-uppercase user-title mb-1'>COST?</p>
                  <div className='faq-ans'>
                    <h3 className='faq-title'>
                      <i className='mdi mdi-help-circle'></i> How much will it
                      cost?
                    </h3>
                    <p className='faq-sub-title text-muted pt-2'>
                      Your vehicle just might be the most expensive possession
                      you have other than your home.
                    </p>
                    <p className='faq-sub-title text-muted pt-2'>
                      However, your auto insurance won't necessarily be costly.
                      While rates vary from state to state and take into account
                      a variety of factors, car insurance is usually fairly
                      affordable.
                    </p>
                    <p className='faq-sub-title text-muted pt-2'>
                      The factors that affect your costs include whether your
                      car is new or used, the overall safety rating of the car,
                      your driving record, your age and gender, and even your
                      ZIP code, as certain areas tend to have a higher
                      occurrence of accidents and claims than others.
                    </p>
                  </div>
                </div>
                <div className='faq pt-5'>
                  <p className='text-uppercase user-title mb-1'>Credit?</p>
                  <div className='faq-ans'>
                    <h3 className='faq-title'>
                      <i className='mdi mdi-help-circle'></i> Does insurance
                      check your credit?
                    </h3>
                    <p className='faq-sub-title text-muted pt-2'>
                      Credit scores and credit reports don’t always tell the
                      full story about a person, but they do indicate your
                      ability to pay your bills. Car insurance companies do
                      check credit as to determine your insurability. Your
                      premium is a bill like any other, and a poor credit score
                      can alert an insurer of financial trouble. If there is a
                      chance that you may miss premium payments, an insurer may
                      decide you are too risky to insure.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </AUX>
    )
  }
}

export default Faq
