import React, { Component } from "react"
import Services from "../Sections/Services"
import Features from "../Sections/Features"
import Counter from "../Sections/Counter"
import Pricing from "../Sections/Pricing"
import Client from "../Sections/Client"
import Faq from "../Sections/Faq"
import { Link } from "react-router-dom"
import AUX from "../../../hoc/Aux_"
import { connect } from "react-redux"
import * as actionTypes from "../../../store/action"
import ModalVideo from "react-modal-video"
import "react-modal-video/scss/modal-video.scss"

class HomeOne extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
    }
    this.openModal = this.openModal.bind(this)
  }

  openModal() {
    this.setState({ isOpen: true })
  }

  componentDidMount() {
    if (this.props.islight === true) {
      this.props.UpdateLight()
    }
  }

  render() {
    const rowsContainer = {
      flexDirection: "column",
      justifyContent: "space-between",
      width: "100%",
    }
    const columnsContainer = {
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
    }

    const rows = (rowNumber) => {
      const rowMap = {
        1: [
          "Flag_of_Guatemala",
          "Flag_of_Mexico",
          "Flag_of_the_United_States",
          "Flag_of_Belize",
          "Flag_of_Honduras_(darker_variant)",
        ],
        2: [
          "Flag_of_El_Salvador",
          "Flag_of_Nicaragua",
          "Flag_of_Costa_Rica_(state)",
          "Flag_of_Panama",
          "Flag_of_Colombia",
        ],
        3: [
          "Flag_of_Venezuela_(state)",
          "Flag_of_Ecuador",
          "Flag_of_Guyana",
          "Flag_of_Brazil",
          "Flag_of_Peru",
        ],
        4: [
          "Bandera_de_Bolivia_(Estado)",
          "Flag_of_Chile",
          "Flag_of_Paraguay",
          "Flag_of_Argentina",
          "Flag_of_Uruguay",
        ],
      }

      const divs = rowMap[rowNumber].map((flagImage) => {
        const imageName = `images/${flagImage}.svg`
        return (
          <img
            src={imageName}
            alt={imageName}
            className='img-fluid center-block home-dashboard'
            style={{ width: "18%", margin: "1%" }}
          />
        )
      })
      return divs
    }
    return (
      <AUX>
        <section className='home-bg-color' id='home'>
          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col-md-10 text-center text-white'>
                <h1 className='mt-5 pt-5 home-title'>
                  All Star Auto Insurance Agency
                </h1>
                <p className='home-subtitle mx-auto pt-2'>
                  We can insure anyone.
                </p>
                <p className='home-subtitle mx-auto pt-2'>
                  We search over 100 insurance companies, giving us the data and
                  abilities to get you the lowest rates, lowest downpayments,
                  and the lowest monthy payments.
                </p>
                <p className='home-subtitle mx-auto pt-2'>A+ Rating with BBB</p>

                {/* <span
                  onClick={this.openModal}
                  className='pt-4 video-play-icon watch-overview d-inline-block text-white'
                >
                  Watch the overview &nbsp;
                  <i className='mdi mdi-chevron-right'></i>
                </span>

                <ModalVideo
                  channel='youtube'
                  isOpen={this.state.isOpen}
                  videoId='JlvxDa7Sges'
                  onClose={() => this.setState({ isOpen: false })}
                /> */}

                <div style={rowsContainer}>
                  <div style={columnsContainer}>{rows(1)}</div>
                  <div style={columnsContainer}>{rows(2)}</div>
                  <div style={columnsContainer}>{rows(3)}</div>
                  <div style={columnsContainer}>{rows(4)}</div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div
          className='row justify-content-center pt-5'
          style={{ marginTop: "50px" }}
        >
          <p
            className='section-subtitle pt-3 text-muted'
            style={{ marginTop: "0px", marginBottom: "0px" }}
          >
            We will insure people from all countries,
            <br />
            through driver's license, foreign
            <br />
            matricula, consular card, or otherwise.
            <br />
          </p>
        </div>

        <Services />
        <Counter />
        <Features />
        {/* <Pricing /> */}
        {/* <Client /> */}
        <Faq />
      </AUX>
    )
  }
}

const mapStatetoProps = (state) => {
  return {
    islight: state.ui_red.islight,
  }
}

const mapDispatchtoProps = (dispatch) => {
  return {
    UpdateLight: () => dispatch({ type: actionTypes.ISLIGHT, value: true }),
  }
}

export default connect(mapStatetoProps, mapDispatchtoProps)(HomeOne)
