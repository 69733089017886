import React, { Component } from "react"
import AUX from "../../../hoc/Aux_"

class Counter extends Component {
  constructor(props) {
    super(props)

    const now = new Date()
    const then = new Date("1994/01/01")

    const seconds = Math.floor((now - then) / 1000)
    const minutes = Math.floor(seconds / 60)
    const hours = Math.floor(minutes / 60)
    const days = Math.floor(hours / 24)
    const years = Math.floor(days / 365)

    this.state = {
      clients: Math.floor(140000 + hours / 2),
      years,
    }
  }

  render() {
    return (
      <AUX>
        <section className='section bg-custom'>
          <div className='container'>
            <div className='row justify-content-center' id='counter'>
              <div className='col-md-3 text-white text-center pt-3 pb-3'>
                <div className='counter-icon'>
                  <i className='mdi mdi-timer'></i>
                </div>
                <h1 className='counter-value' data-count={this.state.years}>
                  {this.state.years}
                </h1>
                <h5 className='counter-name'>Years in Business</h5>
              </div>

              <div className='col-md-3 text-white text-center pt-3 pb-3'>
                <div className='counter-icon'>
                  <i className='mdi mdi-briefcase'></i>
                </div>
                <h1 className='counter-value' data-count='30'>
                  30
                </h1>
                <h5 className='counter-name'>Insurance Coverages</h5>
              </div>

              <div className='col-md-3 text-white text-center pt-3 pb-3'>
                <div className='counter-icon'>
                  <i className='mdi mdi-account'></i>
                </div>
                <h1 className='counter-value' data-count={this.state.clients}>
                  {this.state.clients}
                </h1>
                <h5 className='counter-name'>No. of Clients Helped</h5>
              </div>
              <div className='col-md-3 text-white text-center pt-3 pb-3'>
                <div className='counter-icon'>
                  <i className='mdi mdi-account-multiple'></i>
                </div>
                <h1 className='counter-value' data-count='4'>
                  4
                </h1>
                <h5 className='counter-name'>Team Members</h5>
              </div>
            </div>
          </div>
        </section>
      </AUX>
    )
  }
}

export default Counter
