import React, { Component } from 'react'
import AUX from '../../hoc/Aux_'
import { Link } from 'react-router-dom'

class Topbar extends Component {
  render() {
    return (
      <AUX>
        <div className="topbar">
          <div className="container">
            <div className="float-left">
              <div className="phone-topbar">
                <p className="mb-0" style={{ textAlign: 'left' }}>
                  (650) 583-9474
                </p>
                <p className="mb-0" style={{ textAlign: 'left' }}>
                  allstarinsurance@hotmail.com
                </p>
                <p className="mb-0" style={{ textAlign: 'left' }}>
                  1310 El Camino Real Suite C, San Bruno, CA 94066
                </p>
              </div>
            </div>
            <div className="float-right">
              <ul className="list-inline social pb-0 pt-2">
                <li className="list-inline-item pl-2">
                  <p className="text-white">
                    <i className="mdi mdi-car" />
                  </p>
                </li>
                <li className="list-inline-item pl-2">
                  <p className="text-white">
                    <i className="mdi mdi-motorbike" />
                  </p>
                </li>
                <li className="list-inline-item pl-2">
                  <p className="text-white">
                    <i className="mdi mdi-hospital" />
                  </p>
                </li>
              </ul>
            </div>
            <div className="clearfix" />
          </div>
        </div>
      </AUX>
    )
  }
}

export default Topbar
