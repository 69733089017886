import React, { Component } from "react"
import AUX from "../../../hoc/Aux_"

class Services extends Component {
  render() {
    return (
      <AUX>
        <section className='sectionSpecial' id='services'>
          <div className='container'>
            <div className='row justify-content-center pt-5'>
              <div className='col-md-8'>
                <div className='text-center'>
                  <div className='title-icon'>
                    <i className='mdi mdi-all-inclusive text-custom'></i>
                  </div>
                  <h3 className='section-title pt-5'>Our Services</h3>
                  <p className='section-subtitle pt-3 text-muted'>
                    We can cover everyone, and we can cover just about
                    everything.
                  </p>
                </div>
              </div>
            </div>
            <div className='row mt-5'>
              <div className='col-md-4'>
                <div className='service-box text-center p-4 mt-3'>
                  <div className='service-icon'>
                    <i className='mdi mdi-car text-custom'></i>
                  </div>
                  <div className='service-desc pt-4'>
                    <h5>Auto</h5>
                    <p className='pt-2 text-muted'>
                      Comprehensive Automotive Insurance: Collision Coverage,
                      Comprehensive Coverage, Liability Coverage, you name it.
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='service-box text-center p-4 mt-3'>
                  <div className='service-icon'>
                    <i className='mdi mdi-motorbike text-custom'></i>
                  </div>
                  <div className='service-desc pt-4'>
                    <h5>Motorcyles</h5>
                    <p className='pt-2 text-muted'>
                      Years of experience have given us the expertise dealing
                      with: Uninsured Motorists, Injury, Theft, Liability, and
                      more.
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='service-box text-center p-4 mt-3'>
                  <div className='service-icon'>
                    <i className='mdi mdi-compass-outline text-custom'></i>
                  </div>
                  <div className='service-desc pt-4'>
                    <h5>Boat & R/V</h5>
                    <p className='pt-2 text-muted'>
                      Damage coverage for Capsizing, Collision, Storms, etc.
                      With policies that can cover Medical, Emergencies,
                      Vacation Liability.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-4'>
                <div className='service-box text-center p-4 mt-3'>
                  <div className='service-icon'>
                    <i className='mdi mdi-home text-custom'></i>
                  </div>
                  <div className='service-desc pt-4'>
                    <h5>Home & Renters</h5>
                    <p className='pt-2 text-muted'>
                      A sample of what we can cover includes Condos, Floods,
                      Home Warranty, Individual Property, Mobile Home, and
                      Renters.
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='service-box text-center p-4 mt-3'>
                  <div className='service-icon'>
                    <i className='mdi mdi-hospital text-custom'></i>
                  </div>
                  <div className='service-desc pt-4'>
                    <h5>Life & Health</h5>
                    <p className='pt-2 text-muted'>
                      Ensure that your family has ideal coverage for vision,
                      dental, and physical vitality with All-Star Insurance.
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='service-box text-center p-4 mt-3'>
                  <div className='service-icon'>
                    <i className='mdi mdi-pen text-custom'></i>
                  </div>
                  <div className='service-desc pt-4'>
                    <h5>Commercial</h5>
                    <p className='pt-2 text-muted'>
                      Whether you’re a fledgling company or a thriving
                      corporation, ensure your company, and your employees are
                      protected.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </AUX>
    )
  }
}

export default Services
